import React, { useState, useEffect, useContext} from 'react';
import InputMask from "react-input-mask";
import { Link, navigate } from 'gatsby';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import Preloader from '@components/structure/Preloader';
//MUI
//MUI
import {
	Box,
	TextField,
	Grid,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
} from '@mui/material';
import { checkValiation } from '@components/utility/Validation';
import { StaticImage } from 'gatsby-plugin-image';
import { AppContext } from '@context'
import { Api } from '@components/utility/Api';

const IhRegistration = () => {
	const [getFields, setFields] = useState(() => []);
	const [webinars, setWebinars] = useState(() => []);
	const [loading, setLoading] = useState(() => false);
	const [dobError, setDobError] = useState('You must be at least 18 years old to sign up.');
	let form = '';

	const {selectedWebinars, setSelectedWebinars} = useContext(AppContext);

	const handleSubmit = (e) => {
		e.preventDefault();
		form = e.target;
		const inputs = form.querySelectorAll('input');
		const headingErrorEl = form.querySelector('.heading-error') || null;
		let fieldValid = true;
		let formValid = true;
		let UUIDs = [];

		inputs.forEach(function (element) {
			const elementID = element.name;
			fieldValid = checkValiation(form, elementID, element.type, getFields);

			if (!fieldValid && headingErrorEl) {
				formValid = false;
			}
		});

		if(!formValid) {
			headingErrorEl.classList.add('show-error');
			headingErrorEl.focus();

			let userBirthDate = getFields['dob'];

			const regexVar = new RegExp("([0-9]{2})\/([0-9]{2})\/([0-9]{4})");
			const regexVarTest = regexVar.test(userBirthDate);
			const newDate = userBirthDate && regexVarTest ? new Date(userBirthDate.replace(regexVar, "$3-$1-$2")) : 'Invalid';

			if(isNaN(newDate) || userBirthDate.replace(regexVar, "$3") < 1) {
				setDobError('Please enter a valid date of birth (MM/DD/YYYY).')
			}
			else {
				setDobError('You must be at least 18 years old to sign up.')
			}
		}
		else {
			headingErrorEl.classList.remove('show-error');
			setLoading(true);

			webinars.map((item, index) => {
				UUIDs.push(item[1].UUID)
			});


			let registerData = {};

			let userBirthDate = getFields['dob'];
			if(userBirthDate.length == 8) {
				let tempYear = userBirthDate.split("/")[2];
				if(tempYear.length == 2 && tempYear <= 25) {
					tempYear = 20+tempYear;
					userBirthDate = userBirthDate.split("/")[0]+"/"+userBirthDate.split("/")[1]+"/"+tempYear;
				}
				if(tempYear.length == 2 && tempYear >= 26) {
					tempYear = 19+tempYear;
					userBirthDate = userBirthDate.split("/")[0]+"/"+userBirthDate.split("/")[1]+"/"+tempYear;
				}
			}

			// let registerData = {}; 

			// registerData.eventid = UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0];
			// registerData.firstname = getFields['first-name'];
			// registerData.lastname = getFields['last-name'];
			// registerData.email = getFields['email-address'];
			// registerData.zipcode = getFields['zip-code'];
			// registerData.dateofbirth = userBirthDate;
			// registerData.treatment = getFields['taking-xywav-group'];
			// registerData.mentorinterest = getFields['learn-more'] || "";
			// registerData.treatmentih = getFields['one-applies-group'] || "";
			// registerData.multipleauthorization = "true";

			// if(getFields['learn-more'] && getFields['learn-more-phone']) {
			// 	registerData.phone = getFields['learn-more-phone'];
			// }

			// const success = response => {
			// 	if(response.success) {
			// 		navigate("/xywav-idiopathic-hypersomnia/confirmation")
			// 	}
			// 	else {
			// 		console.log(response);
			// 		setLoading(false);
			// 	}
			// }
			// const fail = error => {
			// 	setLoading(false);
			// 	console.log('Internal Error ', error);
			// }
			// Api.post_register(success, fail, registerData, 4);

			sendDataJazzCX(userBirthDate, UUIDs);
		}
	};

	const sendDataJazzCX = (userBirthDate, UUIDs) => {

		let diagnosisStatus = '';
		switch (getFields['one-applies-group']) {
			case 'stimulant':
				diagnosisStatus = 'I currently take a stimulant for IH';
				break;
			case 'wpa':
				diagnosisStatus = 'I currently take a wake-promoting agent (WPA) for IH';
				break;
			case 'othernotsure':
				diagnosisStatus = 'Other/not sure';
				break;
			case 'paststopped':
				diagnosisStatus = 'I took medication for IH in the past, but stopped';
				break;
			case 'nevertaken':
				diagnosisStatus = 'I’ve never taken medication for IH';
				break;
			case 'notdiagnosed':
				diagnosisStatus = 'I’m not yet diagnosed with IH';
				break;
			default:
				diagnosisStatus = ""
		}

		const registerDataJazzCX = {}
		registerDataJazzCX.firstName = getFields['first-name'];
		registerDataJazzCX.lastName = getFields['last-name'];
		registerDataJazzCX.email = getFields['email-address'];
		registerDataJazzCX.zipCode = getFields['zip-code'];
		registerDataJazzCX.optInEmail = "Yes";
		registerDataJazzCX.dateOfBirth = userBirthDate;
		registerDataJazzCX.eventId = UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0];
		registerDataJazzCX.xywavUsage = getFields['taking-xywav-group'] === 'no' ? 'No' : getFields['taking-xywav-group'] === 'xywav' ? 'Yes' : 'I have a XYWAV prescription and am waiting for my first shipment';
		registerDataJazzCX.mentor = getFields['learn-more'] ? 'Yes' : 'No';
		registerDataJazzCX.dataSource = "JWPS-BRANDED-IH";
		registerDataJazzCX.optInSms = getFields['text-reminder-group'] === 'yes' ? 'Yes' : 'No';
		registerDataJazzCX.phoneNumber = getFields['phone-number'];

		if(diagnosisStatus) {
			registerDataJazzCX.diagnosisStatus = diagnosisStatus;
		}

		const success = response => {
			if(response.success) {
				navigate("/xywav-idiopathic-hypersomnia/confirmation");
			}
			else {
				console.log(response);
			}
			setLoading(false);
		}
		const fail = error => {
			setLoading(false);
			console.log('Internal Error ', error);
		}
		Api.post_register_CX(success, fail, registerDataJazzCX);
	}

	const handleChange = (e) => {
		let fields = getFields;

		fields[e.target.name] =
			e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFields(fields);

		showHiddenQuestion(e);

		if (form) {
			checkValiation(form, e.target.name, e.target.type, getFields);
		}
	};

	const showHiddenQuestion = (element) => {
		if(element.target.name === 'taking-xywav-group') {
			const hiddenQuestion1 = document.querySelector(".hidden-question-1");
			const hiddenQuestion2 = document.querySelector(".hidden-question-2");

			hiddenQuestion2.classList.add("show");

			if(element.target.value === 'no') {
				hiddenQuestion1.classList.add("show");
			}
			else {
				hiddenQuestion1.classList.remove("show");
			}
		}
	}

	const removeClicked = (e, key) => {
		e.preventDefault();
		let newData = [...webinars];

		newData = newData.filter((item, index) => index !== key)
		setWebinars(newData);
		setSelectedWebinars(Object.fromEntries(newData));
		redirectCheck(newData);
	}

	const redirectCheck = (data) => {
		if (!data.length) {
			navigate("/xywav-idiopathic-hypersomnia");
		}
	}

	useEffect(() => {
		const isEDMode = window.location.href.indexOf('ed=1') > -1;
		if(isEDMode) {
			//screenshot
			const success = response => {
				let tempData = {};
				tempData[0] = {
						Date: response[0].Sessions[0].Date,
						ShortDescription: response[0].ShortDescription,
						Topic: response[0].Topic,
						UUID: response[0].Sessions[0].UUID,
				}
				setWebinars(Object.entries(tempData));
			}
			const fail = error => {
				console.log('Internal Error ', error);
			}
			Api.get_webinar(success, fail, "branded", "brandedih");
		}
		else {
			redirectCheck(Object.entries(selectedWebinars));
			setWebinars(Object.entries(selectedWebinars));
		}
	}, []);

	return (
		<Layout pageType='ih-branded' className='idiopathicHypersomnia no-tabs' pixelCode='Start Registration'>
			<Seo title='Idiopathic Hypersomnia Treatment Webinar Registration | Jazz Webinars' description="If you're living with or caring for someone with idiopathic hypersomnia, register today to learn more about a treatment option, gain access to the upcoming narcolepsy live webinars and educational programs." />
			<div>
				<StaticImage
					className='test-desktop'
					src='../../../static/images/ih-branded-hero.png'
					alt='Idiopathic hypersomnia Hero Banner Desktop on register page'
				/>
				<div className='ih-branded-hero-mobile'></div>
				<section className='container firstSection'>
					<Grid container>
						<Grid item xs={12}>
							<span className='back-link'>
								&#8249;  <Link to='/xywav-idiopathic-hypersomnia'>Back to Webinar Library</Link>
							</span>
							<div className='lv-copy v-center tabletPosition'>
								<h1>
									Learn about XYWAV from <br class='mobileOnly' />
									experts in sleep medicine <br class='mobileOnly' />
								</h1>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>

			<section className='container idiopathicHypersomnia'>
				<div>
					<p className='header centerText'>Just one last step!</p>
					<div className='divider'></div>
					<p className='copy'>
						To register for your live webinars, please complete the form
						below.<span className='desktopOnlyContent'> You can view, edit, or remove the webinars you’ve selected
						in the right-hand column.</span>
					</p>
				</div>
				<div className='selectWebinarSection formRight spaceTop'>
					<p className='label upperCase'>Your Selected Webinars</p>
					{webinars ? webinars.map((item, key) => (
						<div className='selectedWebinarItem' key={key}>
							<a href="#" onClick={(e) => {
								removeClicked(e, key);
							}}>Remove</a>
							<Link to='/xywav-idiopathic-hypersomnia'>Edit</Link>
							<p className='header2'>{item[1].Topic}</p>
							<p className='body2'>{item[1].ShortDescription || 'No description provided'}</p>
							<hr className='divider' />
							<p className='timeDate'>TIME &amp; DATE</p>
							<p className='date'>{item[1].Date}</p>
						</div>
					)) : null}
				</div>
				<form
					name='registration'
					method='post'
					onSubmit={handleSubmit}>
					<p className='error-label heading-error' tabIndex='-1'>
						<strong>
							Please be sure to complete or correct the required field(s).
						</strong>
					</p>
					<p className='fieldsRequired'>
						All fields are required unless otherwise specified.
					</p>
					<div className=''>
						<Grid container justifyContent={'space-between'}>
							<Grid item xs={12} className='formLeft spaceTop'>
								<Box
									className='formLeftWidth'
									sx={{
										'& > :not(style)': { m: 1, width: '100%' },
									}}
									noValidate
									autoComplete='off'>
									<label htmlFor='first-name'>First Name</label>
									<p className='error-label first-name'>
										Please enter your First Name.
									</p>
									<TextField
										id='first-name'
										name='first-name'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='last-name'>Last Name</label>
									<p className='error-label last-name'>
										Please enter your Last Name.
									</p>
									<TextField
										id='last-name'
										name='last-name'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='email-address'>Email</label>
									<p className='error-label email-address'>
										Please enter a valid Email Address.
									</p>
									<TextField
										id='email-address'
										name='email-address'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='phone-number'>
											Mobile Phone Number
										</label>
										<p className='error-label phone-number'>
											Please enter a valid Mobile Phone Number.
										</p>
										<TextField
											id='phone-number'
											name='phone-number'
											variant='outlined'
											color='primary'
											inputProps={{ maxLength: 10 }}
											onChange={handleChange}
										/>
									<label htmlFor='zip-code'>ZIP Code</label>
									<p className='error-label zip-code'>
										Please enter a valid ZIP Code.
									</p>
									<InputMask
										mask="99999"
										onChange={handleChange}
										maskChar=""
									>
										{() => <TextField
											id='zip-code'
											name='zip-code'
											variant='outlined'
											color='primary'
										/>}
									</InputMask>
									<label htmlFor='dob'>Date of Birth (MM/DD/YYYY)</label>
									<p className='error-label dob'>
										{dobError}
									</p>
									<InputMask
										mask="99/99/9999"
										onChange={handleChange}
										maskChar=""
									>
										{() => <TextField
											id='dob'
											name='dob'
											variant='outlined'
											color='primary'
										/>}
									</InputMask>
								</Box>
								<div className='formLeftWidth'>
										<p
											id='text-reminder'
											className='textReminderText'>
											<strong>
												Would you like a text reminder?
											</strong>
										</p>
										<p>
											Messaging and data rates may apply. Reply STOP
											to 98637 to cancel and/or HELP for help. Up to
											10 msg/mo.{' '}
											<a href='/terms-conditions' target={"_blank"}>SMS Terms &amp; Conditions</a>
										</p>
										<p className='error-label radio-padding-fix text-reminder-group'>
											Please select an option.
										</p>
										<FormControl>
											<RadioGroup
												row
												onChange={handleChange}
												aria-labelledby='text-reminder'
												name='text-reminder-group'>
												<FormControlLabel
													value='yes'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Yes'
												/>
												<FormControlLabel
													value='no'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='No'
												/>
											</RadioGroup>
										</FormControl>
									</div>
								<div className='formLeftWidth'>
									<p id='taking-xywav'>
										Are you currently taking XYWAV?
									</p>
									<p className='error-label radio-padding-fix taking-xywav-group'>
										Please select an option.
									</p>
									<FormControl>
										<RadioGroup
											column='true'
											onChange={handleChange}
											aria-labelledby='taking-xywav'
											name='taking-xywav-group'>
											<FormControlLabel
												value='xywav'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Yes'
											/>
											<FormControlLabel
												value='xywavwaiting'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='I have a XYWAV prescription and am waiting for my first shipment'
											/>
											<FormControlLabel
												value='no'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='No'
											/>
										</RadioGroup>
									</FormControl>

									<div className="hidden-question-1">
										<p id='one-applies'>
											Please select one that applies:
										</p>
										<p className='error-label radio-padding-fix one-applies-group'>
											Please select an option.
										</p>
										<FormControl onChange={handleChange}>
											<RadioGroup
												column='true'
												onChange={handleChange}
												aria-labelledby='one-applies'
												name='one-applies-group'>
												<FormControlLabel
													value='stimulant'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='I currently take a stimulant for IH'
												/>
												<FormControlLabel
													value='wpa'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='I currently take a wake-promoting agent (WPA) for IH'
												/>
												<FormControlLabel
													value='paststopped'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='I took medication for IH in the past, but stopped'
												/>
												<FormControlLabel
													value='nevertaken'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='I’ve never taken medication for IH'
												/>
												<FormControlLabel
													value='notdiagnosed'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='I’m not yet diagnosed with IH'
												/>
												<FormControlLabel
													value='othernotsure'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Other/not sure'
												/>
											</RadioGroup>
										</FormControl>
									</div>
									<div className='hidden-question-2 checkboxSpaceAbove'>
										<FormControlLabel
											control={
												<Checkbox
													name='learn-more'
													className='learnMoreCheckbox'
													color='primary'
													sx={{
														'& .MuiSvgIcon-root': {
															fontSize: 32,
														},
													}}
													onChange={handleChange}
												/>
											}
											label={
												<span>
													I want to learn more about the XYWAV
													Mentor Program (optional)
													<p>
														The XYWAV Mentor Program connects
														you <br className='desktopOnly' />
														1-on-1 with someone who has
														firsthand experience taking XYWAV
														for IH. XYWAV Patient Mentors
														listen to you and support you,
														providing practical tips and
														answering your questions.
													</p>
												</span>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox
													name='learn-more-phone'
													className='learnMorePhoneCheckbox'
													color='primary'
													sx={{
														'& .MuiSvgIcon-root': {
															fontSize: 32,
														},
													}}
													onChange={handleChange}
												/>
											}
											label={
												<span>
													Want to chat about the XYWAV Mentor Program? Check this box to receive a phone call within 2 business days (optional)
												</span>
											}
										/>
									</div>
									<div className='consentCheckboxContainer'>
										<FormControlLabel
											control={
												<Checkbox
													name='consent'
													color='primary'
													className='consentCheckbox'
													onChange={handleChange}
													sx={{
														'& .MuiSvgIcon-root': {
															fontSize: 32,
														},
													}}
												/>
											}
											label='By checking this box, you confirm that you are a resident of the U.S. and would like to receive information from Jazz Pharmaceuticals about educational programs, products, and services. You can opt out at any time.'
										/>
										<p className='error-label consent'>
											Please check the box above to continue.
										</p>
									</div>
								</div>

								<div className='registerButton'>
									<a href='https://www.jazzpharma.com/privacy-statement/' className='privacy' target="_blank">
										Privacy Statement
									</a>{' '}
									<br />
									{!loading ?
										<CtaButton
											title='REGISTER'
											class='cta main-cta registerButtonItem'
											type='submit'
											disabled={loading}
										/>
									: <Preloader/> }
								</div>
							</Grid>
						</Grid>
					</div>
				</form>
			</section>
		</Layout>
	);
};

export default IhRegistration;